import * as types from '../mutation-types'

export const fetchShippingMethodShips = (
  { dispatch, commit },
  { model, options }
) => {
  commit(types.API_REQUEST_START, 'fetchShippingMethodShips')

  return new Promise((resolve, reject) => {
    model
      .fetchShippingMethodShips(options)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'fetchShippingMethodShips')

        dispatch(
          'partnerShippingMethodShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'shippingMethods/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchShippingMethodShips,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const createShippingMethodShip = (
  { dispatch, commit },
  { model, shippingMethodId }
) => {
  commit(types.API_REQUEST_START, 'createShippingMethodShip')

  return new Promise((resolve, reject) => {
    model
      .createShippingMethodShip({ shippingMethodId })
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'createShippingMethodShip')

        dispatch(
          'partnerShippingMethodShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'shippingMethods/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: createShippingMethodShip,
            ref: {
              dispatch,
              commit
            },
            params: { model, shippingMethodId }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroyShippingMethodShip = (
  { dispatch, commit },
  { model, id }
) => {
  commit(types.API_REQUEST_START, 'destroyShippingMethodShip')

  return new Promise((resolve, reject) => {
    model
      .destroyShippingMethodShip(id)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'destroyShippingMethodShip')

        dispatch(
          'partnerShippingMethodShips/receiveDestroyResourceFromRelationships',
          id,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroyShippingMethodShip,
            ref: {
              dispatch,
              commit
            },
            params: { model, id }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
