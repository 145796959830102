import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_campaign_share_ships',
  attributes: [
    'id',
    'campaign_id',
    'share_id',
    'state',
    'campaign_periods_count',
    'campaign_period_share_ids',
    'has_sample',
    'created_at',
    'updated_at'
  ],
  editableAttributes: [
    'campaign_period_ids',
    'contact_phone',
    'sample_address',
    'receiver_name',
    'receiver_note',
    'remember_sample_address'
  ]
}

export default class PromoterCampaignShareShip extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)

    if (!this.id && !this.state) this.state = 'waited'
    if (!this.sample_address || !this.sample_address.city)
      this.sample_address = { city: null, area: null, street_address: null }
  }

  static rejectedReason = (isHtml) => {
    const link = 'https://kolnet.ec/kolcenter/official_campaigns'

    return isHtml
      ? [
          '<p>由於特定因素，您報名的檔期未獲取廠商的審核通過。然而，這並不影響您參與我們其他檔期的機會。KOLNET誠邀您探索更多即將開放的檔期</p>',
          '<br>',
          `<p>🔍 官方檔期一覽：<a href='${link}' target='_blank'>${link}</a>`,
          '<br>',
          '<p>點擊上述網址即可了解更多訊息，非常期待與您未來的合作機會!!💙</p>'
        ].join('')
      : [
          '由於特定因素，您報名的檔期未獲取廠商的審核通過。然而，這並不影響您參與我們其他檔期的機會。KOLNET誠邀您探索更多即將開放的檔期',
          '',
          `🔍 官方檔期一覽：${link}`,
          '',
          '點擊上述網址即可了解更多訊息，非常期待與您未來的合作機會!!💙'
        ].join('\n')
  }

  static register({ share_id, campaign_id, model }) {
    return axios.put(
      `${new this().apiBasePath()}/register?promoter_share_id=${share_id}&promoter_campaign_id=${campaign_id}`,
      {
        data: {
          type: 'campaign-share-ship-register',
          attributes: model
        }
      }
    )
  }

  acceptRegistration(form) {
    return axios.put(`${this.apiBasePath()}/${this.id}/accept_registration`, {
      data: {
        type: 'accept-registration',
        attributes: form
      }
    })
  }

  static acceptInvitation({ share_id, campaign_id }) {
    return axios.put(
      `${new this().apiBasePath()}/accept_invitation?promoter_share_id=${share_id}&promoter_campaign_id=${campaign_id}`
    )
  }

  reject(form) {
    return axios.put(`${this.apiBasePath()}/${this.id}/reject`, {
      data: {
        type: 'reject',
        attributes: form
      }
    })
  }

  // for stats
  static fetchModelStats(options = {}) {
    let queryString = FetchingDataOptionsService.call(options)

    return axios.get(`${new this().apiBasePath()}/model_stats?${queryString}`)
  }

  updatePeriods(periodIds) {
    return axios.post(`${this.apiBasePath()}/${this.id}/periods`, {
      data: {
        type: 'update-campaign-period-shares',
        attributes: {
          period_ids: periodIds
        }
      }
    })
  }
}
