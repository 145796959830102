import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'site_config',
  attributes: [
    'meta_tags',
    'trackers',
    'domain_verification',
    'mobile_quick_link_settings',
    'site_contacts',
    'notification_system',
    'store_preferences',
    'site_custom_display_config',
    'shop_com_config',
    'app_payment_gateways',
    'environment',
    'rewards_program',
    'site_feature_configs',
    'sms_service_configs',
    'auth_service_configs',
    'line_bot',
    'reward',
    'social_media'
  ],
  editableAttributes: [
    'meta_tags',
    'trackers',
    'domain_verification',
    'mobile_quick_link_settings',
    'site_contacts',
    'notification_system',
    'store_preferences',
    'shop_com_config',
    'rewards_program',
    'site_custom_display_config',
    'sms_service_configs',
    'auth_service_configs',
    'line_bot',
    'reward',
    'social_media'
  ]
}

export default class SiteConfig extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static fetchAuthServiceConfig() {
    return axios.get(`${new this().apiBasePath()}/auth_service_configs`)
  }

  static fetchCustomDisplayConfig() {
    return axios.get(`${new this().apiBasePath()}/custom_display`)
  }

  static fetchWebsitePreferences() {
    return axios.get(`${new this().apiBasePath()}/website_preferences`)
  }

  static fetchStorePreferences() {
    return axios.get(`${new this().apiBasePath()}/store_preferences`)
  }

  static fetchMobileQuickLinks() {
    return axios.get(`${new this().apiBasePath()}/mobile_quick_links`)
  }

  static fetchRewardsProgram() {
    return axios.get(`${new this().apiBasePath()}/rewards_program`)
  }

  static fetchLineBotConfig() {
    return axios.get(`${new this().apiBasePath()}/line_bot`)
  }

  static fetchRewardConfig() {
    return axios.get(`${new this().apiBasePath()}/reward`)
  }

  static fetchSmsServiceConfigs() {
    return axios.get(`${new this().apiBasePath()}/sms_service_configs`)
  }

  static fetchSmsProviderCredit(provider) {
    return axios.get(
      `${new this().apiBasePath()}/sms_service_configs/provider_credits?provider=${provider}`
    )
  }

  static fetchServerIpAddress() {
    return axios.get(
      `${new this().apiBasePath()}/sms_service_configs/server_ip_address`
    )
  }

  static updateHolidayDatabase() {
    return axios.post(`${new this().apiBasePath()}/update_holiday_database`)
  }

  static appPaymentGateways() {
    return axios.get(`${new this().apiBasePath()}/app_payment_gateways`)
  }

  static updateDataPosition(data) {
    return axios.put(`${new this().apiBasePath()}/data_positioning`, {
      data: {
        type: OPTIONS.resourceType,
        attributes: data
      }
    })
  }

  static getSiteFeatureConfigs() {
    return axios.get(`${new this().apiBasePath()}/site_feature_configs`)
  }

  updateLogo(formData) {
    return axios.put(
      `${this.apiBasePath()}/custom_display/update_logo`,
      formData
    )
  }

  updateWebsitePreferences() {
    const scopes = ['meta_tags', 'trackers', 'domain_verification']
    let result = {}

    scopes.forEach((scope) => {
      Object.keys(this[scope]).forEach((key) => {
        result[`${scope}.${key}`] = this[scope][key]
      })
    })

    return axios.put(
      `${this.apiBasePath()}/website_preferences`,
      this.partialConfigRequestBody(result)
    )
  }

  updateStorePreferences() {
    const scopes = [
      'notification_system',
      'store_preferences',
      'rewards_program'
    ]
    let result = {}

    scopes.forEach((scope) => {
      Object.keys(this[scope]).forEach((key) => {
        result[`${scope}.${key}`] = this[scope][key]
      })
    })

    return axios.put(
      `${this.apiBasePath()}/store_preferences`,
      this.partialConfigRequestBody(result)
    )
  }

  static updateCustomDisplayConfig(config) {
    const instance = new this()

    return axios.put(
      `${instance.apiBasePath()}/custom_display`,
      instance.partialConfigRequestBody(config)
    )
  }

  updateSmsServiceConfigs() {
    const key1 = 'sms_service_configs'
    let result = {}

    Object.keys(this[key1]).forEach((key2) => {
      if (typeof this[key1][key2] !== 'object' || this[key1][key2] == null)
        return

      Object.keys(this[key1][key2]).forEach((key3) => {
        result[`${key1}.${key2}.${key3}`] = this[key1][key2][key3]
      })
    })
    return axios.put(
      `${this.apiBasePath()}/sms_service_configs`,
      this.partialConfigRequestBody(result)
    )
  }

  updateMobileQuickLinks() {
    return axios.put(
      `${this.apiBasePath()}/sms_service_configs`,
      this.partialConfigRequestBody(result)
    )
  }

  static updateMobileQuickLinks(formData) {
    return axios.put(`${new this().apiBasePath()}/mobile_quick_links`, formData)
  }

  updateSiteContacts() {
    return axios.put(
      `${this.apiBasePath()}/site_contacts`,
      this.partialConfigRequestBody(this.site_contacts)
    )
  }

  updateRmaConfig() {
    return axios.put(
      `${this.apiBasePath()}/rma`,
      this.partialConfigRequestBody(this.rma)
    )
  }

  updateShopComConfig() {
    return axios.put(
      `${this.apiBasePath()}/shop_com_config`,
      this.partialConfigRequestBody(this.shop_com_config)
    )
  }

  updateRewardsProgram() {
    return axios.put(
      `${this.apiBasePath()}/rewards_program`,
      this.partialConfigRequestBody(this.rewards_program)
    )
  }

  updateLineBotConfig() {
    return axios.put(
      `${this.apiBasePath()}/line_bot`,
      this.partialConfigRequestBody(this.line_bot)
    )
  }

  updateRewardConfig() {
    return axios.put(
      `${this.apiBasePath()}/reward`,
      this.partialConfigRequestBody(this.reward)
    )
  }

  updateAuthServiceConfig() {
    const key1 = 'auth_service_configs'
    let result = {}

    Object.keys(this[key1]).forEach((key2) => {
      if (typeof this[key1][key2] !== 'object' || this[key1][key2] == null)
        return

      Object.keys(this[key1][key2]).forEach((key3) => {
        result[`${key2}.${key3}`] = this[key1][key2][key3]
      })
    })

    return axios.put(
      `${this.apiBasePath()}/auth_service_configs`,
      this.partialConfigRequestBody(result)
    )
  }

  static fetchSocialMedia() {
    return axios.get(`${new this().apiBasePath()}/social_media`)
  }

  updateSocialMedia() {
    return axios.put(
      `${this.apiBasePath()}/social_media`,
      this.partialConfigRequestBody(this.social_media)
    )
  }

  // helpers

  /**
   * 僅更新特定 configs 時使用的 request body
   *
   * @param {Object} configs
   * @returns {Object} JSON:API 格式的 request body
   * @memberof SiteConfig
   */
  partialConfigRequestBody(configs) {
    return {
      data: {
        type: OPTIONS.resourceType,
        attributes: configs
      }
    }
  }
}
